var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 12, md: 12, sm: 24, xl: 12, xs: 24 } },
            [_c("trend")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [_c("branch")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [_c("rank")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [_c("tabs")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }