var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workbench-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 6, xs: 24 } },
            [_c("order")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 16, md: 12, sm: 24, xl: 18, xs: 24 } },
            [_c("target")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }