var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "el-card",
        {
          staticClass: "order-card1",
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("vab-icon", { attrs: { icon: "shopping-bag-2-line" } }),
                  _vm._v(" 商品 "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-row",
            { staticClass: "order-card1-content" },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("p", [_vm._v("已售数量")]),
                _c(
                  "h1",
                  [
                    _c("vab-count", {
                      attrs: {
                        decimals: _vm.countConfig.decimals,
                        duration: _vm.countConfig.duration,
                        "end-val": _vm.countConfig.endVal,
                        prefix: _vm.countConfig.prefix,
                        separator: _vm.countConfig.separator,
                        "start-val": _vm.countConfig.startVal,
                        suffix: _vm.countConfig.suffix,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("p", [_vm._v("待售数量")]),
                _c(
                  "h1",
                  [
                    _c("vab-count", {
                      attrs: {
                        decimals: _vm.countConfig.decimals,
                        duration: _vm.countConfig.duration,
                        "end-val": _vm.countConfig.endVal,
                        prefix: _vm.countConfig.prefix,
                        separator: _vm.countConfig.separator,
                        "start-val": _vm.countConfig.startVal,
                        suffix: _vm.countConfig.suffix,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("p", [_vm._v("好评度")]),
                _c("h1", [_vm._v("99%")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "order-card2",
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "span",
                    [
                      _c("vab-icon", { attrs: { icon: "list-unordered" } }),
                      _vm._v(" 订单 "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-row",
            { staticClass: "order-card2-content" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("p", [_vm._v("已完成订单")]),
                _c(
                  "h1",
                  [
                    _c("vab-count", {
                      attrs: {
                        decimals: _vm.countConfig.decimals,
                        duration: _vm.countConfig.duration,
                        "end-val": _vm.countConfig.endVal * 1.5,
                        prefix: _vm.countConfig.prefix,
                        separator: _vm.countConfig.separator,
                        "start-val": _vm.countConfig.startVal,
                        suffix: _vm.countConfig.suffix,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("p", [_vm._v("计划完成订单")]),
                _c(
                  "h1",
                  [
                    _c("vab-count", {
                      attrs: {
                        decimals: _vm.countConfig.decimals,
                        duration: _vm.countConfig.duration,
                        "end-val": _vm.countConfig.endVal * 2.5,
                        prefix: _vm.countConfig.prefix,
                        separator: _vm.countConfig.separator,
                        "start-val": _vm.countConfig.startVal,
                        suffix: _vm.countConfig.suffix,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("vab-chart", {
                    staticClass: "order-chart",
                    attrs: {
                      "init-options": _vm.initOptions,
                      option: _vm.option,
                      theme: "vab-echarts-theme",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }