var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trend" },
    [
      _c(
        "el-card",
        {
          staticClass: "trend-card",
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "span",
                    [
                      _c("vab-icon", { attrs: { icon: "line-chart-fill" } }),
                      _vm._v(" 趋势 "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("vab-chart", {
            staticClass: "trend-echart",
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option,
              theme: "vab-echarts-theme",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }