<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="70px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="账单周期">
            <el-input v-model="listQuery.billCycle" placeholder="账单周期" />
          </el-form-item>
          <el-form-item label="账单名称">
            <el-input v-model="listQuery.billName" placeholder="账单名称" />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input v-model="listQuery.shopName" placeholder="商户名称" />
          </el-form-item>

          <el-form-item label="房源名称">
            <el-input v-model="listQuery.houseName" placeholder="房源名称" />
          </el-form-item>

          <el-form-item label="用户名称">
            <el-input v-model="listQuery.memberName" placeholder="用户名称" />
          </el-form-item>
          <el-form-item label="合同编号">
            <el-input v-model="listQuery.code" placeholder="合同编号" />
          </el-form-item>
          <el-form-item label="账单项目">
            <el-select v-model="listQuery.type" placeholder="账单项目">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-top-panel>
        <el-form>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账单周期 "
        prop="billCycle"
        width="100"
      />
      <el-table-column
        align="center"
        label="账单名称"
        prop="billName"
        width="150"
      />
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        width="200"
      />
      <el-table-column
        align="center"
        label="楼栋名称"
        prop="buildingName"
        width="100"
      />
      <el-table-column
        align="center"
        label="房源名称"
        prop="houseName"
        width="150"
      />
      <el-table-column
        align="center"
        label="用户名称"
        prop="memberName"
        width="150"
      />
      <el-table-column
        align="center"
        label="合同编号"
        prop="code"
        width="250"
      />
      <el-table-column
        align="center"
        label="科目名称"
        prop="billSubjectName"
        width="150"
      />
      <el-table-column
        align="center"
        label="单价"
        prop="unitPrice"
        width="150"
      />
      <el-table-column
        align="center"
        label="单价名称"
        prop="unitPriceName"
        width="150"
      />
      <el-table-column
        align="center"
        label="原价(元)"
        prop="xxxx"
        width="100"
      />
      <el-table-column
        align="center"
        label="应收金额(元)"
        prop="receivableAmount"
        width="150"
      />
      <el-table-column
        align="center"
        label="实收金额"
        prop="realAmount"
        width="100"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="200"
      />
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-view"
            plain
            size="mini"
            type="primary"
            @click="handleDetail(scope.row)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="200px"
          :model="formData"
          size="small"
        >
          <el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="账单周期">
                  <span>{{ formData.billCycle }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账单名称">
                  <span>{{ formData.billName }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="商户名称">
                  <span>{{ formData.shopName }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="楼栋名称">
                  <span>{{ formData.buildingName }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="房屋名称">
                  <span>{{ formData.houseName }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户名称">
                  <span>{{ formData.memberName }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="合同编号">
                  <span>{{ formData.code }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="科目名称">
                  <span>{{ formData.billSubjectName }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="单价">
                  <span>{{ formData.unitPrice }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单价名称">
                  <span>{{ formData.unitPriceName }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="原价(元)">
                  <span>{{ formData.xxxx }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="应收金额(元)">
                  <span>{{ formData.receivableAmount }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="实收金额(元)">
                  <span>{{ formData.realAmount }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="创建时间">
                  <span>{{ formData.createTime }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="更新时间">
                  <span>{{ formData.updateTime }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { sheetList } from '@/api/bill/sheet'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialogOther.vue'
  export default {
    name: 'Index',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,

        dailog: {
          height: 400,
          title: '',
          visible: false,
          width: '900px',
        },
        formData: {
          code: '',
        },

        ddd: 'zc',

        options: [
          {
            value: '',
            label: '请选择账单项目',
          },
          {
            value: 1001,
            label: '租金',
          },
          {
            value: 2001,
            label: '房租押金',
          },
          {
            value: 2002,
            label: '门卡押金',
          },
          {
            value: 2003,
            label: '钥匙押金',
          },
          {
            value: 3001,
            label: '用电综合费',
          },
          {
            value: 3002,
            label: '用水综合费',
          },
          // {
          //   value: 3003,
          //   label: '热水费',
          // },
          {
            value: 4001,
            label: '物业费',
          },
          {
            value: 4002,
            label: '公摊费用',
          },
        ],
        value: '',
        input: '',
        listQuery: {
          billCycle: '',
          billName: '',
          shopName: '',
          houseName: '',
          memberName: '',
          code: '',
          type: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      getList() {
        this.loading = true
        sheetList(this.listQuery).then((res) => {
          if (res.code === 0) {
            // console.log('res>>>', res.data)
            this.tableData = res.data.list
            this.totalCount = res.data.totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        // console.log('单选===', selection)
        console.log('单选===', row)
      },
      //编辑
      handleDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '账单明细查看'
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        // console.log(this.formData, 588)
        this.$refs['formData'].validate((valid) => {
          // console.log(valid)
          if (valid) {
            // menuSave(this.formData).then((res) => {
            //   this.commonMessage(res)
            // })
          }
        })
      },
      //刷新
      refresh() {
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
