<template>
  <div class="index-container"></div>
</template>

<script>
  export default {
    name: 'Center',
    data() {
      return {}
    },
    mounted() {
      this.$router.push({ path: '/home' })
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    padding: 0 !important;
    background: $base-color-background !important;
  }
</style>
