<template>
  <div class="index">
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          <span>
            {{
              (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in columnList"
        :key="item.name"
        align="center"
        :label="item.name"
        :prop="item.prop"
        show-overflow-tooltip
        :width="item.width"
      />
      <el-table-column align="center" label="操作" prop="address" width="50">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleCheckDetail(scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <el-drawer
      :before-close="handleClose"
      :direction="direction"
      :size="'50%'"
      :title="drawerTitle"
      :visible.sync="drawer"
    >
      <div class="content">
        <el-descriptions
          border
          class="margin-top"
          :column="2"
          :size="size"
          title=""
        >
          <el-descriptions-item>
            <template slot="label">房号</template>
            {{ drawerData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ drawerData.shopName }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'Info',
    components: {
      Pagination,
    },
    props: {
      tableData: {
        type: Array,
        default() {
          return []
        },
      },
      columnList: {
        type: Array,
        default() {
          return []
        },
      },
      query: {
        type: Object,
        default() {
          return {}
        },
      },
      totalCount: {
        type: Number,
        default: 0,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      drawerTitle: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        listQuery: {
          pageNumber: 1,
          pageSize: 10,
        },
        direction: 'rtl',
        drawer: false,
        size: '',
        drawerData: {},
        ids: '',
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.$emit('getList')
      },
      handleCheckDetail() {},
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      handleClose() {
        this.drawer = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
      }
    }
    .addButton {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .content {
      padding: 0 20px;
    }
    :deep() {
      .el-table--border .el-table__cell:first-child .cell {
        padding-left: 0;
      }
      .el-table th.el-table__cell > .cell {
        padding-right: 0;
      }
      .el-table .cell {
        padding: 0;
      }
    }
  }
</style>
